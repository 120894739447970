/*　ハンバーガーボタン　*/
.hamburger {
  display: block;
  position: fixed;
  z-index: 3;
  right: 0px;
  top: 12px;
  width: 42px;
  height: 42px;
  cursor: pointer;
  text-align: center;
}
.hamburger span {
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  left: 6px;
  background: #c3b189;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.hamburger span:nth-child(1) {
  top: 10px;
}
.hamburger span:nth-child(2) {
  top: 20px;
}
.hamburger span:nth-child(3) {
  top: 30px;
}

/* ナビ開いてる時のボタン */
.hamburger.active span:nth-child(1) {
  top: 16px;
  left: 6px;
  background: #c3b189;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.hamburger.active span:nth-child(2),
.hamburger.active span:nth-child(3) {
  top: 16px;
  background: #c3b189;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
}

nav.globalMenuSp {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  color: #c3b189;
  background: rgba(0, 0, 0, 0.7);
  text-align: center;
  height: 100vh;
  width: 100vw;
  // opacity: 0;
  // visibility: hidden;
  // transition: opacity 0.6s ease;
  // , visibility .6s ease;
  display: none;
}

nav.globalMenuSp ul {
  margin: 0 auto;
  padding: 0;
  width: 100%;
}

nav.globalMenuSp ul li {
  list-style-type: none;
  padding: 0;
  width: 100%;
  transition: 0.4s all;
}
nav.globalMenuSp ul li:last-child {
  padding-bottom: 0;
}
nav.globalMenuSp ul li:hover {
  background: rgba(255, 255, 255, 0.2);
}

nav.globalMenuSp ul li a {
  display: block;
  color: #c3b189;
  padding: 0.6em 0;
  text-decoration: none;
}

/* このクラスを、jQueryで付与・削除する */
nav.globalMenuSp.active {
  // opacity: 100;
  // visibility: visible;
  display: block;

}
