#style-lg {
  .height1_6vw {
    height: 1.6vw;
    line-height: 1.6vw;
    font-size: 1.4vw;
  }
  .height2_0vw {
    height: 2vw;
    line-height: 2vw;
    font-size: 1.8vw;
  }

  .square-right {
    position: relative;
    height: 40vw;
    width: 40vw;
    left: 50vw;
    top: -11.8vw;
    padding-left: 7vw;
    padding-right: 7vw;
    padding-top: 7vw;
    padding-bottom: 7vw;
  }

  .angle--top-left {
    position: relative;
    overflow: hidden;
    -webkit-clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 100%);
    clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% 100%, 0 100%);
  }
  .angle__content {
    display: block;
    width: 100%;
    height: auto;
  }

  .square-right2 {
    position: relative;
    height: 100vh;
    width: 40vw;
    left: 50vw;
    top: -11.8vw;
    padding-left: 7vw;
    padding-right: 7vw;
    padding-top: 7vw;
    padding-bottom: 7vw;
  }

  .square-opacity {
    position: relative;
    height: 40vw;
    width: 80%;
    top: -10vw;
    // width: 100%;
    // left: -10%;
    // background-color: #9a887b;
    background-color: #1b1b1b;
    // background-color: #000000;
    opacity: 0.8;
    padding-top: 11vw;
    padding-right: 5vw;
    padding-left: 8vw;
    padding-bottom: 8vw;
    color: white;
  }

  .square-left {
    position: relative;
    height: 40vw;
    width: 80%;
    top: -10vw;
    left: 20%;

    padding-top: 11vw;
    padding-right: 5vw;
    padding-left: 8vw;
    padding-bottom: 8vw;
  }

  .rectangle-left {
    position: relative;
    height: 40vw;
    width: 40vw;
    left: 10vw;
    top: -11.8vw;
    padding-left: 7vw;
    padding-right: 7vw;
    padding-top: 7vw;
    padding-bottom: 7vw;
  }

  .rectangle-right {
    position: relative;
    height: 40vw;
    width: 40vw;
    left: 50vw;
    top: -11.8vw;
    padding-left: 7vw;
    padding-right: 7vw;
    padding-top: 7vw;
    padding-bottom: 7vw;
  }

  .rectangle-right-opacity {
    position: relative;
    height: 100vh;
    width: 90%;
    top: 15vw;
    left: -10%;
    background-color: rgba(0, 0, 0, 0.29);
  }

  .rectangle-right-transparent {
    position: relative;
    height: 100vh;
    width: 90%;
    top: 15vw;
    left: -10%;
    background-color: transparent;
  }

  .rectangle-left-opacity {
    position: relative;
    height: 100vh;
    width: 90%;
    top: 15vw;
    left: 20%;
    background-color: rgba(0, 0, 0, 0.29);
  }

  .rectangle-left-transparent {
    position: relative;
    height: 100vh;
    width: 90%;
    top: 15vw;
    left: 20%;
    background-color: transparent;
  }

  .rectangle-right-beige {
    position: relative;
    height: 45vw;
    // width: 130%;
    width: 100%;
    top: 80vh;
    left: -10%;
    right: 0;
    // background-color: #fff;
    // color: black;
    // background-color: #9a887b;
    color: white;
    // background-color: black;
    background-color: #1b1b1b;
    // color: white;

    padding-top: 13vw;
    padding-right: 13vw;
    padding-left: 13vw;
    padding-bottom: 13vw;
  }

  .rectangle-right-white {
    position: relative;
    height: 50vw;
    // width: 130%;
    width: 100%;
    top: 80vh;
    left: -10%;
    right: 0;
    background-color: #fff;
    color: black;
    // background-color: #9a887b;
    // color: white;
    // background-color: black;
    // background-color: #1b1b1b;
    // color: white;

    padding-top: 13vw;
    padding-right: 13vw;
    padding-left: 13vw;
    padding-bottom: 13vw;
  }

  .rectangle-right-side {
    position: relative;
    height: 50vw;
    width: 15%;
    top: 0vw;
    // left: 30%;
    right: 0;
    // background-color: black;
    // background-color: #1C2015;
    // background-color: #9a887b;
    background-color: #000000;
    color: white;
    // background-color: white;
    // color: black;
  }
}
