#style-sm {
  .height4vw {
    height: 4vw;
    line-height: 4vw;
    font-size: 3vw;
  }
  .height5vw {
    height: 5vw;
    line-height: 5vw;
    font-size: 4vw;
  }

  .square-right {
    position: relative;
    // height: 76vw;
    height: 80vw;
    width: 80vw;
    left: 20vw;
    top: -24vw;
    padding-left: 12vw;
    padding-right: 12vw;
    padding-top: 12vw;
    padding-bottom: 12vw;
  }

  .rectangle-left-transparent {
    position: relative;
    height: 100vh;
    width: 90%;
    top: 15vw;
    // left: 20%;
    background-color: transparent;
  }

  .rectangle-right-opacity {
    position: relative;
    height: 100vh;
    width: 120%;
    top: 45vh;
    left: -20%;
    background-color: rgba(0, 0, 0, 0.29);
  }

  .rectangle-left {
    // position: relative;
    // height: 55vh;
    // width: 100%;
    // top: -15vh;
    // left: 0%;

    // padding-top: 8vw;
    // padding-right: 8vw;
    // padding-left: 8vw;
    // padding-bottom: 8vw;

    position: relative;
    height: 80vw;
    // height: 76vw;
    width: 80vw;
    left: 0vw;
    top: -24vw;
    padding-left: 12vw;
    padding-right: 12vw;
    padding-top: 12vw;
    padding-bottom: 12vw;


  }

  .rectangle-left-opacity {
    position: relative;
    height: 100vh;
    width: 120%;
    top: 55vh;
    left: 0%;
    background-color: rgba(0, 0, 0, 0.29);
  }

  .rectangle-right {
    // position: relative;
    // height: 55vh;
    // width: 100%;
    // top: -15vh;
    // left: 0%;

    // padding-top: 8vw;
    // padding-right: 8vw;
    // padding-left: 8vw;
    // padding-bottom: 8vw;
    position: relative;
    height: 80vw;
    width: 80vw;
    left: 20vw;
    top: -24vw;
    padding-left: 12vw;
    padding-right: 12vw;
    padding-top: 12vw;
    padding-bottom: 12vw;


  }
}

#parallax {
  // overscroll-behavior: "contain";
  // touch-action: "none";
}
