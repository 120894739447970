.card-register {
  background-color: #def5ff;
  // background-color: #EEEEEE;
  // mkty
  border-radius: 8px;
  // color: #fff;
  // mkty
  // max-width: 350px;
  // mkty
  margin: 20px 0 70px;
  // min-height: 400px;
  // mkty
  padding: 30px;

  & label {
    margin-top: 15px;
  }
  & .card-title {
    // color: #b33c12;
    color: #45c7ff;
  // mkty

    text-align: center;
  }
  & .btn {
    margin-top: 30px;
  }
  & .forgot {
    text-align: center;
  }
}

.card-smart {
  max-width: 350px;
  // mkty
}

.card-wide {
  max-width: 450px;
  // mkty
}

.card-extra {
  max-width: 1200px;
  // mkty
}