// html {
//   height: 100%;
//   background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
//   overflow: hidden;
// }

#stars {
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: 1053px 1525px #fff, 1011px 1850px #fff, 842px 1643px #fff,
    1858px 373px #fff, 1066px 1749px #fff, 1458px 357px #fff, 1702px 288px #fff,
    1066px 1593px #fff, 602px 904px #fff, 563px 411px #fff, 1570px 665px #fff,
    1996px 352px #fff, 822px 1274px #fff, 545px 1214px #fff, 1253px 1955px #fff,
    363px 667px #fff, 1731px 1619px #fff, 1832px 790px #fff, 774px 1492px #fff,
    313px 954px #fff, 1015px 1560px #fff, 1700px 1520px #fff, 1873px 667px #fff,
    1322px 707px #fff, 886px 327px #fff, 1576px 1167px #fff, 106px 968px #fff,
    1388px 1340px #fff, 746px 591px #fff, 1139px 1075px #fff, 820px 636px #fff,
    264px 1088px #fff, 278px 120px #fff, 1793px 470px #fff, 1857px 935px #fff,
    967px 4px #fff, 795px 142px #fff, 1236px 373px #fff, 1069px 1014px #fff,
    1523px 1756px #fff, 1482px 600px #fff, 967px 364px #fff, 1751px 1465px #fff,
    509px 1761px #fff, 758px 1042px #fff, 1318px 1393px #fff, 1611px 375px #fff,
    1931px 35px #fff, 444px 1253px #fff, 1305px 972px #fff, 760px 1688px #fff,
    517px 19px #fff, 1292px 458px #fff, 1055px 1066px #fff, 1086px 1436px #fff,
    923px 13px #fff, 1562px 465px #fff, 1839px 1412px #fff, 152px 536px #fff,
    464px 1572px #fff, 579px 768px #fff, 187px 846px #fff, 654px 678px #fff,
    718px 813px #fff, 1863px 1452px #fff, 1536px 741px #fff, 1968px 206px #fff,
    1774px 240px #fff, 1104px 889px #fff, 308px 724px #fff, 1218px 1179px #fff,
    635px 907px #fff, 1066px 537px #fff, 1278px 1283px #fff, 1964px 1402px #fff,
    876px 983px #fff, 1158px 1500px #fff, 1583px 706px #fff, 1225px 1144px #fff,
    51px 1388px #fff, 1005px 1206px #fff, 1428px 1397px #fff, 690px 332px #fff,
    1324px 1543px #fff, 1207px 924px #fff, 1362px 508px #fff, 705px 818px #fff,
    1096px 1859px #fff, 648px 1732px #fff, 530px 1064px #fff, 1134px 270px #fff,
    1883px 336px #fff, 494px 839px #fff, 261px 876px #fff, 1310px 193px #fff,
    1427px 269px #fff, 1197px 1329px #fff, 900px 820px #fff, 676px 715px #fff,
    1683px 1292px #fff, 1523px 697px #fff, 1006px 1462px #fff, 1338px 668px #fff,
    1483px 317px #fff, 725px 166px #fff, 992px 1065px #fff, 1885px 1924px #fff,
    1579px 1180px #fff, 1679px 1904px #fff, 272px 883px #fff, 1879px 1166px #fff,
    89px 1139px #fff, 377px 1329px #fff, 780px 1783px #fff, 1655px 849px #fff,
    182px 1778px #fff, 1438px 1251px #fff, 171px 1341px #fff, 1364px 754px #fff,
    455px 1809px #fff, 1496px 1436px #fff, 556px 1318px #fff, 135px 804px #fff,
    1839px 751px #fff, 338px 1395px #fff, 802px 1650px #fff, 956px 1059px #fff,
    1239px 1204px #fff, 360px 10px #fff, 1367px 992px #fff, 1192px 1260px #fff,
    135px 1593px #fff, 505px 1427px #fff, 1669px 811px #fff, 1248px 1738px #fff,
    531px 1117px #fff, 161px 355px #fff, 1649px 329px #fff, 936px 353px #fff,
    978px 1796px #fff, 1116px 1947px #fff, 534px 1555px #fff, 22px 96px #fff,
    871px 528px #fff, 1052px 1071px #fff, 706px 495px #fff, 641px 293px #fff,
    1742px 1128px #fff, 1951px 442px #fff, 86px 1156px #fff, 1676px 1842px #fff,
    1565px 110px #fff, 946px 1104px #fff, 471px 264px #fff, 1824px 1545px #fff,
    1456px 1692px #fff, 1212px 1123px #fff, 408px 1777px #fff,
    1801px 1459px #fff, 1323px 1680px #fff, 430px 190px #fff, 1710px 913px #fff,
    14px 603px #fff, 991px 182px #fff, 141px 1672px #fff, 1028px 1787px #fff,
    24px 1044px #fff, 1335px 1022px #fff, 1375px 1679px #fff, 1372px 1188px #fff,
    1200px 126px #fff, 1045px 163px #fff, 1530px 368px #fff, 1904px 1160px #fff,
    456px 1959px #fff, 423px 1152px #fff, 1605px 474px #fff, 936px 1918px #fff,
    85px 1249px #fff, 692px 1454px #fff, 1275px 1221px #fff, 27px 1625px #fff,
    2000px 1580px #fff, 1617px 916px #fff, 1953px 821px #fff, 1406px 290px #fff,
    734px 1902px #fff, 1904px 1328px #fff, 213px 1956px #fff, 349px 123px #fff,
    810px 1701px #fff, 1665px 1548px #fff, 1263px 1873px #fff,
    1715px 1597px #fff, 610px 1080px #fff, 718px 1560px #fff, 1525px 1325px #fff,
    167px 1387px #fff, 1591px 1635px #fff, 1453px 313px #fff, 1182px 1502px #fff,
    334px 1077px #fff, 580px 1241px #fff, 45px 731px #fff, 1375px 1055px #fff,
    226px 1688px #fff, 197px 1573px #fff, 1647px 1493px #fff, 319px 466px #fff,
    1384px 1367px #fff, 1074px 896px #fff, 273px 1633px #fff, 1075px 1018px #fff,
    1525px 904px #fff, 1746px 800px #fff, 1805px 115px #fff, 1256px 1733px #fff,
    227px 1012px #fff, 1447px 1002px #fff, 1775px 1219px #fff, 826px 301px #fff,
    888px 1035px #fff, 534px 1201px #fff, 1333px 454px #fff, 1564px 893px #fff,
    789px 1081px #fff, 1881px 1730px #fff, 832px 1941px #fff, 1369px 716px #fff,
    1767px 747px #fff, 1588px 1718px #fff, 1485px 502px #fff, 577px 328px #fff,
    1065px 1068px #fff, 650px 987px #fff, 1679px 17px #fff, 1376px 512px #fff,
    1383px 1224px #fff, 460px 1211px #fff, 283px 148px #fff, 1687px 1643px #fff,
    96px 951px #fff, 1057px 470px #fff, 1254px 814px #fff, 1158px 870px #fff,
    860px 1032px #fff, 1343px 1342px #fff, 1711px 1662px #fff, 1470px 929px #fff,
    86px 932px #fff, 414px 878px #fff, 207px 1484px #fff, 1404px 1539px #fff,
    1371px 630px #fff, 1222px 1600px #fff, 236px 778px #fff, 420px 1170px #fff,
    1177px 667px #fff, 309px 1086px #fff, 1603px 545px #fff, 441px 1942px #fff,
    984px 1687px #fff, 683px 1151px #fff, 1898px 1633px #fff, 1656px 710px #fff,
    230px 1586px #fff, 1525px 1011px #fff, 473px 365px #fff, 186px 440px #fff,
    781px 600px #fff, 1454px 1841px #fff, 1773px 478px #fff, 681px 1848px #fff,
    425px 444px #fff, 1315px 218px #fff, 763px 199px #fff, 1093px 50px #fff,
    1423px 305px #fff, 1034px 278px #fff, 1590px 313px #fff, 1005px 1302px #fff,
    1845px 290px #fff, 640px 1385px #fff, 52px 1403px #fff, 1623px 1146px #fff,
    1982px 1952px #fff, 20px 1244px #fff, 840px 236px #fff, 736px 1254px #fff,
    290px 810px #fff, 1893px 81px #fff, 1754px 1156px #fff, 1087px 682px #fff,
    899px 870px #fff, 435px 1980px #fff, 296px 1541px #fff, 1405px 209px #fff,
    1166px 861px #fff, 1092px 1515px #fff, 1239px 1762px #fff, 1599px 948px #fff,
    262px 1887px #fff, 913px 1237px #fff, 1504px 321px #fff, 732px 882px #fff,
    1960px 1689px #fff, 485px 204px #fff, 1817px 1517px #fff, 1064px 912px #fff,
    144px 1383px #fff, 1534px 1577px #fff, 756px 1786px #fff, 113px 992px #fff,
    1424px 1075px #fff, 5px 1055px #fff, 1109px 1877px #fff, 1673px 544px #fff,
    1683px 1930px #fff, 1074px 1794px #fff, 220px 918px #fff, 1320px 1536px #fff,
    214px 916px #fff, 443px 1176px #fff, 1260px 307px #fff, 1500px 384px #fff,
    1689px 1916px #fff, 1093px 182px #fff, 1399px 623px #fff, 1369px 296px #fff,
    619px 1678px #fff, 1949px 1329px #fff, 135px 1964px #fff, 1670px 1165px #fff,
    845px 286px #fff, 547px 791px #fff, 416px 38px #fff, 26px 1561px #fff,
    1191px 1867px #fff, 41px 589px #fff, 13px 1705px #fff, 884px 176px #fff,
    565px 1254px #fff, 33px 433px #fff, 404px 1433px #fff, 306px 77px #fff,
    558px 1428px #fff, 188px 1869px #fff, 362px 1225px #fff, 1930px 1211px #fff,
    42px 664px #fff, 967px 1262px #fff, 1961px 1448px #fff, 963px 1068px #fff,
    592px 498px #fff, 1308px 1860px #fff, 259px 387px #fff, 1695px 559px #fff,
    1171px 1058px #fff, 1591px 131px #fff, 581px 228px #fff, 1109px 247px #fff,
    67px 1345px #fff, 98px 238px #fff, 1306px 1287px #fff, 763px 1060px #fff,
    1289px 1816px #fff, 1051px 1324px #fff, 890px 1471px #fff, 415px 181px #fff,
    1770px 983px #fff, 725px 1262px #fff, 1240px 923px #fff, 1894px 531px #fff,
    586px 753px #fff, 1705px 1034px #fff, 1528px 156px #fff, 828px 1135px #fff,
    1631px 1297px #fff, 601px 1376px #fff, 170px 1827px #fff, 1669px 575px #fff,
    1929px 1982px #fff, 106px 1179px #fff, 519px 1941px #fff, 1479px 66px #fff,
    1263px 275px #fff, 530px 464px #fff, 858px 1727px #fff, 547px 91px #fff,
    1700px 300px #fff, 1935px 1550px #fff, 1278px 1717px #fff, 356px 287px #fff,
    299px 1274px #fff, 869px 1482px #fff, 207px 1459px #fff, 1812px 1040px #fff,
    1475px 291px #fff, 1962px 579px #fff, 1971px 149px #fff, 1825px 1278px #fff,
    545px 625px #fff, 1796px 96px #fff, 637px 1020px #fff, 1817px 293px #fff,
    1127px 1892px #fff, 1416px 1378px #fff, 1537px 1950px #fff, 600px 516px #fff,
    763px 1101px #fff, 1386px 436px #fff, 967px 80px #fff, 289px 1663px #fff,
    1897px 1763px #fff, 1548px 668px #fff, 1554px 1681px #fff, 1437px 932px #fff,
    1254px 418px #fff, 636px 850px #fff, 1252px 169px #fff, 1707px 1882px #fff,
    29px 1143px #fff, 1414px 1617px #fff, 597px 1362px #fff, 259px 1707px #fff,
    302px 1870px #fff, 309px 1988px #fff, 411px 1149px #fff, 102px 62px #fff,
    616px 687px #fff, 168px 1342px #fff, 630px 241px #fff, 1675px 1137px #fff,
    748px 1272px #fff, 647px 1089px #fff, 675px 1745px #fff, 1819px 1948px #fff,
    1200px 49px #fff, 1552px 1501px #fff, 250px 760px #fff, 823px 1683px #fff,
    1017px 879px #fff, 731px 744px #fff, 1737px 894px #fff, 279px 828px #fff,
    1661px 897px #fff, 170px 502px #fff, 1188px 1205px #fff, 1989px 1236px #fff,
    1041px 1848px #fff, 974px 399px #fff, 1534px 106px #fff, 1436px 527px #fff,
    1711px 316px #fff, 1921px 1686px #fff, 1310px 1198px #fff, 119px 214px #fff,
    1008px 1005px #fff, 1671px 213px #fff, 442px 1968px #fff, 90px 1134px #fff,
    1923px 471px #fff, 1446px 563px #fff, 351px 545px #fff, 891px 610px #fff,
    1863px 1855px #fff, 443px 1897px #fff, 1695px 1715px #fff, 881px 1277px #fff,
    896px 1466px #fff, 1846px 1168px #fff, 1513px 436px #fff, 1374px 1671px #fff,
    932px 1209px #fff, 1330px 1078px #fff, 1234px 1115px #fff, 1771px 113px #fff,
    140px 557px #fff, 1839px 1935px #fff, 1145px 418px #fff, 741px 1611px #fff,
    265px 1236px #fff, 377px 1019px #fff, 1886px 203px #fff, 709px 1820px #fff,
    556px 1684px #fff, 57px 1674px #fff, 1360px 208px #fff, 1015px 216px #fff,
    226px 1428px #fff, 1856px 1301px #fff, 1972px 1987px #fff, 512px 1250px #fff,
    301px 1149px #fff, 599px 1101px #fff, 345px 1319px #fff, 334px 1565px #fff,
    793px 487px #fff, 1988px 1931px #fff, 1005px 1327px #fff, 808px 32px #fff,
    154px 179px #fff, 708px 665px #fff, 1497px 1494px #fff, 570px 529px #fff,
    1643px 1382px #fff, 892px 1900px #fff, 1402px 323px #fff, 1453px 789px #fff,
    691px 1125px #fff, 1634px 62px #fff, 1398px 1389px #fff, 1695px 922px #fff,
    319px 1222px #fff, 289px 26px #fff, 48px 758px #fff, 1977px 115px #fff,
    1965px 1859px #fff, 778px 1250px #fff, 413px 1471px #fff, 1420px 1843px #fff,
    28px 949px #fff, 689px 329px #fff, 918px 1077px #fff, 825px 1998px #fff,
    1857px 1880px #fff, 714px 835px #fff, 1468px 840px #fff, 914px 1967px #fff,
    1467px 1244px #fff, 811px 644px #fff, 190px 273px #fff, 360px 1262px #fff,
    469px 128px #fff, 439px 323px #fff, 1091px 900px #fff, 158px 1442px #fff,
    1065px 501px #fff, 590px 1897px #fff, 480px 1916px #fff, 1192px 74px #fff,
    1211px 1853px #fff, 886px 985px #fff, 912px 1354px #fff, 1657px 1617px #fff,
    526px 55px #fff, 877px 577px #fff, 1259px 1780px #fff, 186px 161px #fff,
    1901px 407px #fff, 1470px 1335px #fff, 1670px 160px #fff, 565px 345px #fff,
    1072px 1081px #fff, 412px 1993px #fff, 684px 151px #fff, 282px 912px #fff,
    373px 1485px #fff, 536px 243px #fff, 331px 407px #fff, 863px 165px #fff,
    1005px 454px #fff, 1310px 666px #fff, 757px 1020px #fff, 792px 1123px #fff,
    1218px 1030px #fff, 1392px 542px #fff, 837px 1584px #fff, 1070px 794px #fff,
    387px 149px #fff, 1420px 217px #fff, 382px 1097px #fff, 123px 1945px #fff,
    1289px 605px #fff, 1623px 1410px #fff, 1419px 617px #fff, 377px 728px #fff,
    173px 1168px #fff, 924px 721px #fff, 1967px 794px #fff, 1431px 352px #fff,
    1782px 1757px #fff, 1647px 153px #fff, 1220px 258px #fff, 29px 1134px #fff,
    717px 354px #fff, 1649px 20px #fff, 1124px 412px #fff, 1314px 920px #fff,
    53px 929px #fff, 778px 1411px #fff, 1460px 693px #fff, 822px 550px #fff,
    33px 1007px #fff, 1120px 195px #fff, 1036px 1402px #fff, 1367px 1842px #fff,
    1938px 1803px #fff, 1780px 819px #fff, 188px 1756px #fff, 788px 1029px #fff,
    112px 1477px #fff, 982px 1073px #fff, 1282px 691px #fff, 1976px 1341px #fff,
    1505px 250px #fff, 988px 1770px #fff, 532px 580px #fff, 779px 258px #fff,
    19px 981px #fff, 224px 1796px #fff, 841px 1022px #fff, 1264px 805px #fff,
    1437px 1871px #fff, 733px 465px #fff, 1897px 1078px #fff, 1131px 534px #fff,
    1789px 819px #fff, 559px 1624px #fff, 1016px 1648px #fff, 20px 1319px #fff,
    47px 43px #fff, 474px 89px #fff, 1831px 1418px #fff, 116px 1990px #fff,
    994px 1893px #fff, 18px 1342px #fff, 150px 611px #fff, 225px 1374px #fff,
    260px 1805px #fff, 781px 1585px #fff, 1432px 574px #fff, 34px 1092px #fff,
    1733px 1902px #fff, 1860px 1575px #fff, 1021px 1456px #fff,
    1783px 675px #fff, 1914px 1441px #fff, 1050px 1040px #fff,
    1738px 1375px #fff, 1936px 1024px #fff, 612px 1923px #fff,
    1253px 1748px #fff, 787px 637px #fff, 682px 1775px #fff, 1631px 767px #fff,
    1200px 263px #fff, 1511px 1766px #fff, 1602px 642px #fff, 1774px 1531px #fff,
    1303px 1308px #fff, 369px 604px #fff, 1695px 84px #fff, 1853px 627px #fff,
    561px 537px #fff, 1106px 134px #fff, 1015px 1066px #fff, 1577px 1813px #fff,
    1615px 366px #fff, 665px 101px #fff, 1129px 1669px #fff, 1566px 565px #fff,
    1461px 1805px #fff, 1361px 750px #fff, 1641px 869px #fff, 460px 354px #fff,
    1337px 1463px #fff, 1177px 1157px #fff, 850px 691px #fff, 774px 24px #fff,
    1014px 921px #fff, 533px 348px #fff, 1964px 1256px #fff, 1640px 801px #fff,
    156px 1351px #fff, 836px 595px #fff, 1181px 1783px #fff, 1815px 291px #fff,
    1583px 1470px #fff, 329px 1003px #fff, 438px 476px #fff, 810px 628px #fff,
    512px 814px #fff, 573px 1289px #fff, 1146px 1261px #fff, 365px 372px #fff,
    1984px 159px #fff, 1345px 1562px #fff, 1137px 1787px #fff, 744px 1969px #fff,
    554px 1893px #fff, 670px 1590px #fff, 1819px 1317px #fff, 898px 92px #fff,
    1202px 1934px #fff, 512px 1797px #fff, 601px 682px #fff, 1045px 1966px #fff,
    1435px 1006px #fff, 1164px 43px #fff;
  animation: animStar 50s linear infinite;
}
#stars:after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: 1053px 1525px #fff, 1011px 1850px #fff, 842px 1643px #fff,
    1858px 373px #fff, 1066px 1749px #fff, 1458px 357px #fff, 1702px 288px #fff,
    1066px 1593px #fff, 602px 904px #fff, 563px 411px #fff, 1570px 665px #fff,
    1996px 352px #fff, 822px 1274px #fff, 545px 1214px #fff, 1253px 1955px #fff,
    363px 667px #fff, 1731px 1619px #fff, 1832px 790px #fff, 774px 1492px #fff,
    313px 954px #fff, 1015px 1560px #fff, 1700px 1520px #fff, 1873px 667px #fff,
    1322px 707px #fff, 886px 327px #fff, 1576px 1167px #fff, 106px 968px #fff,
    1388px 1340px #fff, 746px 591px #fff, 1139px 1075px #fff, 820px 636px #fff,
    264px 1088px #fff, 278px 120px #fff, 1793px 470px #fff, 1857px 935px #fff,
    967px 4px #fff, 795px 142px #fff, 1236px 373px #fff, 1069px 1014px #fff,
    1523px 1756px #fff, 1482px 600px #fff, 967px 364px #fff, 1751px 1465px #fff,
    509px 1761px #fff, 758px 1042px #fff, 1318px 1393px #fff, 1611px 375px #fff,
    1931px 35px #fff, 444px 1253px #fff, 1305px 972px #fff, 760px 1688px #fff,
    517px 19px #fff, 1292px 458px #fff, 1055px 1066px #fff, 1086px 1436px #fff,
    923px 13px #fff, 1562px 465px #fff, 1839px 1412px #fff, 152px 536px #fff,
    464px 1572px #fff, 579px 768px #fff, 187px 846px #fff, 654px 678px #fff,
    718px 813px #fff, 1863px 1452px #fff, 1536px 741px #fff, 1968px 206px #fff,
    1774px 240px #fff, 1104px 889px #fff, 308px 724px #fff, 1218px 1179px #fff,
    635px 907px #fff, 1066px 537px #fff, 1278px 1283px #fff, 1964px 1402px #fff,
    876px 983px #fff, 1158px 1500px #fff, 1583px 706px #fff, 1225px 1144px #fff,
    51px 1388px #fff, 1005px 1206px #fff, 1428px 1397px #fff, 690px 332px #fff,
    1324px 1543px #fff, 1207px 924px #fff, 1362px 508px #fff, 705px 818px #fff,
    1096px 1859px #fff, 648px 1732px #fff, 530px 1064px #fff, 1134px 270px #fff,
    1883px 336px #fff, 494px 839px #fff, 261px 876px #fff, 1310px 193px #fff,
    1427px 269px #fff, 1197px 1329px #fff, 900px 820px #fff, 676px 715px #fff,
    1683px 1292px #fff, 1523px 697px #fff, 1006px 1462px #fff, 1338px 668px #fff,
    1483px 317px #fff, 725px 166px #fff, 992px 1065px #fff, 1885px 1924px #fff,
    1579px 1180px #fff, 1679px 1904px #fff, 272px 883px #fff, 1879px 1166px #fff,
    89px 1139px #fff, 377px 1329px #fff, 780px 1783px #fff, 1655px 849px #fff,
    182px 1778px #fff, 1438px 1251px #fff, 171px 1341px #fff, 1364px 754px #fff,
    455px 1809px #fff, 1496px 1436px #fff, 556px 1318px #fff, 135px 804px #fff,
    1839px 751px #fff, 338px 1395px #fff, 802px 1650px #fff, 956px 1059px #fff,
    1239px 1204px #fff, 360px 10px #fff, 1367px 992px #fff, 1192px 1260px #fff,
    135px 1593px #fff, 505px 1427px #fff, 1669px 811px #fff, 1248px 1738px #fff,
    531px 1117px #fff, 161px 355px #fff, 1649px 329px #fff, 936px 353px #fff,
    978px 1796px #fff, 1116px 1947px #fff, 534px 1555px #fff, 22px 96px #fff,
    871px 528px #fff, 1052px 1071px #fff, 706px 495px #fff, 641px 293px #fff,
    1742px 1128px #fff, 1951px 442px #fff, 86px 1156px #fff, 1676px 1842px #fff,
    1565px 110px #fff, 946px 1104px #fff, 471px 264px #fff, 1824px 1545px #fff,
    1456px 1692px #fff, 1212px 1123px #fff, 408px 1777px #fff,
    1801px 1459px #fff, 1323px 1680px #fff, 430px 190px #fff, 1710px 913px #fff,
    14px 603px #fff, 991px 182px #fff, 141px 1672px #fff, 1028px 1787px #fff,
    24px 1044px #fff, 1335px 1022px #fff, 1375px 1679px #fff, 1372px 1188px #fff,
    1200px 126px #fff, 1045px 163px #fff, 1530px 368px #fff, 1904px 1160px #fff,
    456px 1959px #fff, 423px 1152px #fff, 1605px 474px #fff, 936px 1918px #fff,
    85px 1249px #fff, 692px 1454px #fff, 1275px 1221px #fff, 27px 1625px #fff,
    2000px 1580px #fff, 1617px 916px #fff, 1953px 821px #fff, 1406px 290px #fff,
    734px 1902px #fff, 1904px 1328px #fff, 213px 1956px #fff, 349px 123px #fff,
    810px 1701px #fff, 1665px 1548px #fff, 1263px 1873px #fff,
    1715px 1597px #fff, 610px 1080px #fff, 718px 1560px #fff, 1525px 1325px #fff,
    167px 1387px #fff, 1591px 1635px #fff, 1453px 313px #fff, 1182px 1502px #fff,
    334px 1077px #fff, 580px 1241px #fff, 45px 731px #fff, 1375px 1055px #fff,
    226px 1688px #fff, 197px 1573px #fff, 1647px 1493px #fff, 319px 466px #fff,
    1384px 1367px #fff, 1074px 896px #fff, 273px 1633px #fff, 1075px 1018px #fff,
    1525px 904px #fff, 1746px 800px #fff, 1805px 115px #fff, 1256px 1733px #fff,
    227px 1012px #fff, 1447px 1002px #fff, 1775px 1219px #fff, 826px 301px #fff,
    888px 1035px #fff, 534px 1201px #fff, 1333px 454px #fff, 1564px 893px #fff,
    789px 1081px #fff, 1881px 1730px #fff, 832px 1941px #fff, 1369px 716px #fff,
    1767px 747px #fff, 1588px 1718px #fff, 1485px 502px #fff, 577px 328px #fff,
    1065px 1068px #fff, 650px 987px #fff, 1679px 17px #fff, 1376px 512px #fff,
    1383px 1224px #fff, 460px 1211px #fff, 283px 148px #fff, 1687px 1643px #fff,
    96px 951px #fff, 1057px 470px #fff, 1254px 814px #fff, 1158px 870px #fff,
    860px 1032px #fff, 1343px 1342px #fff, 1711px 1662px #fff, 1470px 929px #fff,
    86px 932px #fff, 414px 878px #fff, 207px 1484px #fff, 1404px 1539px #fff,
    1371px 630px #fff, 1222px 1600px #fff, 236px 778px #fff, 420px 1170px #fff,
    1177px 667px #fff, 309px 1086px #fff, 1603px 545px #fff, 441px 1942px #fff,
    984px 1687px #fff, 683px 1151px #fff, 1898px 1633px #fff, 1656px 710px #fff,
    230px 1586px #fff, 1525px 1011px #fff, 473px 365px #fff, 186px 440px #fff,
    781px 600px #fff, 1454px 1841px #fff, 1773px 478px #fff, 681px 1848px #fff,
    425px 444px #fff, 1315px 218px #fff, 763px 199px #fff, 1093px 50px #fff,
    1423px 305px #fff, 1034px 278px #fff, 1590px 313px #fff, 1005px 1302px #fff,
    1845px 290px #fff, 640px 1385px #fff, 52px 1403px #fff, 1623px 1146px #fff,
    1982px 1952px #fff, 20px 1244px #fff, 840px 236px #fff, 736px 1254px #fff,
    290px 810px #fff, 1893px 81px #fff, 1754px 1156px #fff, 1087px 682px #fff,
    899px 870px #fff, 435px 1980px #fff, 296px 1541px #fff, 1405px 209px #fff,
    1166px 861px #fff, 1092px 1515px #fff, 1239px 1762px #fff, 1599px 948px #fff,
    262px 1887px #fff, 913px 1237px #fff, 1504px 321px #fff, 732px 882px #fff,
    1960px 1689px #fff, 485px 204px #fff, 1817px 1517px #fff, 1064px 912px #fff,
    144px 1383px #fff, 1534px 1577px #fff, 756px 1786px #fff, 113px 992px #fff,
    1424px 1075px #fff, 5px 1055px #fff, 1109px 1877px #fff, 1673px 544px #fff,
    1683px 1930px #fff, 1074px 1794px #fff, 220px 918px #fff, 1320px 1536px #fff,
    214px 916px #fff, 443px 1176px #fff, 1260px 307px #fff, 1500px 384px #fff,
    1689px 1916px #fff, 1093px 182px #fff, 1399px 623px #fff, 1369px 296px #fff,
    619px 1678px #fff, 1949px 1329px #fff, 135px 1964px #fff, 1670px 1165px #fff,
    845px 286px #fff, 547px 791px #fff, 416px 38px #fff, 26px 1561px #fff,
    1191px 1867px #fff, 41px 589px #fff, 13px 1705px #fff, 884px 176px #fff,
    565px 1254px #fff, 33px 433px #fff, 404px 1433px #fff, 306px 77px #fff,
    558px 1428px #fff, 188px 1869px #fff, 362px 1225px #fff, 1930px 1211px #fff,
    42px 664px #fff, 967px 1262px #fff, 1961px 1448px #fff, 963px 1068px #fff,
    592px 498px #fff, 1308px 1860px #fff, 259px 387px #fff, 1695px 559px #fff,
    1171px 1058px #fff, 1591px 131px #fff, 581px 228px #fff, 1109px 247px #fff,
    67px 1345px #fff, 98px 238px #fff, 1306px 1287px #fff, 763px 1060px #fff,
    1289px 1816px #fff, 1051px 1324px #fff, 890px 1471px #fff, 415px 181px #fff,
    1770px 983px #fff, 725px 1262px #fff, 1240px 923px #fff, 1894px 531px #fff,
    586px 753px #fff, 1705px 1034px #fff, 1528px 156px #fff, 828px 1135px #fff,
    1631px 1297px #fff, 601px 1376px #fff, 170px 1827px #fff, 1669px 575px #fff,
    1929px 1982px #fff, 106px 1179px #fff, 519px 1941px #fff, 1479px 66px #fff,
    1263px 275px #fff, 530px 464px #fff, 858px 1727px #fff, 547px 91px #fff,
    1700px 300px #fff, 1935px 1550px #fff, 1278px 1717px #fff, 356px 287px #fff,
    299px 1274px #fff, 869px 1482px #fff, 207px 1459px #fff, 1812px 1040px #fff,
    1475px 291px #fff, 1962px 579px #fff, 1971px 149px #fff, 1825px 1278px #fff,
    545px 625px #fff, 1796px 96px #fff, 637px 1020px #fff, 1817px 293px #fff,
    1127px 1892px #fff, 1416px 1378px #fff, 1537px 1950px #fff, 600px 516px #fff,
    763px 1101px #fff, 1386px 436px #fff, 967px 80px #fff, 289px 1663px #fff,
    1897px 1763px #fff, 1548px 668px #fff, 1554px 1681px #fff, 1437px 932px #fff,
    1254px 418px #fff, 636px 850px #fff, 1252px 169px #fff, 1707px 1882px #fff,
    29px 1143px #fff, 1414px 1617px #fff, 597px 1362px #fff, 259px 1707px #fff,
    302px 1870px #fff, 309px 1988px #fff, 411px 1149px #fff, 102px 62px #fff,
    616px 687px #fff, 168px 1342px #fff, 630px 241px #fff, 1675px 1137px #fff,
    748px 1272px #fff, 647px 1089px #fff, 675px 1745px #fff, 1819px 1948px #fff,
    1200px 49px #fff, 1552px 1501px #fff, 250px 760px #fff, 823px 1683px #fff,
    1017px 879px #fff, 731px 744px #fff, 1737px 894px #fff, 279px 828px #fff,
    1661px 897px #fff, 170px 502px #fff, 1188px 1205px #fff, 1989px 1236px #fff,
    1041px 1848px #fff, 974px 399px #fff, 1534px 106px #fff, 1436px 527px #fff,
    1711px 316px #fff, 1921px 1686px #fff, 1310px 1198px #fff, 119px 214px #fff,
    1008px 1005px #fff, 1671px 213px #fff, 442px 1968px #fff, 90px 1134px #fff,
    1923px 471px #fff, 1446px 563px #fff, 351px 545px #fff, 891px 610px #fff,
    1863px 1855px #fff, 443px 1897px #fff, 1695px 1715px #fff, 881px 1277px #fff,
    896px 1466px #fff, 1846px 1168px #fff, 1513px 436px #fff, 1374px 1671px #fff,
    932px 1209px #fff, 1330px 1078px #fff, 1234px 1115px #fff, 1771px 113px #fff,
    140px 557px #fff, 1839px 1935px #fff, 1145px 418px #fff, 741px 1611px #fff,
    265px 1236px #fff, 377px 1019px #fff, 1886px 203px #fff, 709px 1820px #fff,
    556px 1684px #fff, 57px 1674px #fff, 1360px 208px #fff, 1015px 216px #fff,
    226px 1428px #fff, 1856px 1301px #fff, 1972px 1987px #fff, 512px 1250px #fff,
    301px 1149px #fff, 599px 1101px #fff, 345px 1319px #fff, 334px 1565px #fff,
    793px 487px #fff, 1988px 1931px #fff, 1005px 1327px #fff, 808px 32px #fff,
    154px 179px #fff, 708px 665px #fff, 1497px 1494px #fff, 570px 529px #fff,
    1643px 1382px #fff, 892px 1900px #fff, 1402px 323px #fff, 1453px 789px #fff,
    691px 1125px #fff, 1634px 62px #fff, 1398px 1389px #fff, 1695px 922px #fff,
    319px 1222px #fff, 289px 26px #fff, 48px 758px #fff, 1977px 115px #fff,
    1965px 1859px #fff, 778px 1250px #fff, 413px 1471px #fff, 1420px 1843px #fff,
    28px 949px #fff, 689px 329px #fff, 918px 1077px #fff, 825px 1998px #fff,
    1857px 1880px #fff, 714px 835px #fff, 1468px 840px #fff, 914px 1967px #fff,
    1467px 1244px #fff, 811px 644px #fff, 190px 273px #fff, 360px 1262px #fff,
    469px 128px #fff, 439px 323px #fff, 1091px 900px #fff, 158px 1442px #fff,
    1065px 501px #fff, 590px 1897px #fff, 480px 1916px #fff, 1192px 74px #fff,
    1211px 1853px #fff, 886px 985px #fff, 912px 1354px #fff, 1657px 1617px #fff,
    526px 55px #fff, 877px 577px #fff, 1259px 1780px #fff, 186px 161px #fff,
    1901px 407px #fff, 1470px 1335px #fff, 1670px 160px #fff, 565px 345px #fff,
    1072px 1081px #fff, 412px 1993px #fff, 684px 151px #fff, 282px 912px #fff,
    373px 1485px #fff, 536px 243px #fff, 331px 407px #fff, 863px 165px #fff,
    1005px 454px #fff, 1310px 666px #fff, 757px 1020px #fff, 792px 1123px #fff,
    1218px 1030px #fff, 1392px 542px #fff, 837px 1584px #fff, 1070px 794px #fff,
    387px 149px #fff, 1420px 217px #fff, 382px 1097px #fff, 123px 1945px #fff,
    1289px 605px #fff, 1623px 1410px #fff, 1419px 617px #fff, 377px 728px #fff,
    173px 1168px #fff, 924px 721px #fff, 1967px 794px #fff, 1431px 352px #fff,
    1782px 1757px #fff, 1647px 153px #fff, 1220px 258px #fff, 29px 1134px #fff,
    717px 354px #fff, 1649px 20px #fff, 1124px 412px #fff, 1314px 920px #fff,
    53px 929px #fff, 778px 1411px #fff, 1460px 693px #fff, 822px 550px #fff,
    33px 1007px #fff, 1120px 195px #fff, 1036px 1402px #fff, 1367px 1842px #fff,
    1938px 1803px #fff, 1780px 819px #fff, 188px 1756px #fff, 788px 1029px #fff,
    112px 1477px #fff, 982px 1073px #fff, 1282px 691px #fff, 1976px 1341px #fff,
    1505px 250px #fff, 988px 1770px #fff, 532px 580px #fff, 779px 258px #fff,
    19px 981px #fff, 224px 1796px #fff, 841px 1022px #fff, 1264px 805px #fff,
    1437px 1871px #fff, 733px 465px #fff, 1897px 1078px #fff, 1131px 534px #fff,
    1789px 819px #fff, 559px 1624px #fff, 1016px 1648px #fff, 20px 1319px #fff,
    47px 43px #fff, 474px 89px #fff, 1831px 1418px #fff, 116px 1990px #fff,
    994px 1893px #fff, 18px 1342px #fff, 150px 611px #fff, 225px 1374px #fff,
    260px 1805px #fff, 781px 1585px #fff, 1432px 574px #fff, 34px 1092px #fff,
    1733px 1902px #fff, 1860px 1575px #fff, 1021px 1456px #fff,
    1783px 675px #fff, 1914px 1441px #fff, 1050px 1040px #fff,
    1738px 1375px #fff, 1936px 1024px #fff, 612px 1923px #fff,
    1253px 1748px #fff, 787px 637px #fff, 682px 1775px #fff, 1631px 767px #fff,
    1200px 263px #fff, 1511px 1766px #fff, 1602px 642px #fff, 1774px 1531px #fff,
    1303px 1308px #fff, 369px 604px #fff, 1695px 84px #fff, 1853px 627px #fff,
    561px 537px #fff, 1106px 134px #fff, 1015px 1066px #fff, 1577px 1813px #fff,
    1615px 366px #fff, 665px 101px #fff, 1129px 1669px #fff, 1566px 565px #fff,
    1461px 1805px #fff, 1361px 750px #fff, 1641px 869px #fff, 460px 354px #fff,
    1337px 1463px #fff, 1177px 1157px #fff, 850px 691px #fff, 774px 24px #fff,
    1014px 921px #fff, 533px 348px #fff, 1964px 1256px #fff, 1640px 801px #fff,
    156px 1351px #fff, 836px 595px #fff, 1181px 1783px #fff, 1815px 291px #fff,
    1583px 1470px #fff, 329px 1003px #fff, 438px 476px #fff, 810px 628px #fff,
    512px 814px #fff, 573px 1289px #fff, 1146px 1261px #fff, 365px 372px #fff,
    1984px 159px #fff, 1345px 1562px #fff, 1137px 1787px #fff, 744px 1969px #fff,
    554px 1893px #fff, 670px 1590px #fff, 1819px 1317px #fff, 898px 92px #fff,
    1202px 1934px #fff, 512px 1797px #fff, 601px 682px #fff, 1045px 1966px #fff,
    1435px 1006px #fff, 1164px 43px #fff;
}

#stars2 {
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: 71px 795px #fff, 57px 324px #fff, 1321px 88px #fff,
    1964px 677px #fff, 180px 802px #fff, 360px 16px #fff, 1528px 321px #fff,
    1244px 1926px #fff, 1130px 1729px #fff, 1239px 1058px #fff,
    1571px 504px #fff, 741px 1733px #fff, 1980px 98px #fff, 748px 243px #fff,
    1321px 1434px #fff, 1010px 1711px #fff, 1109px 1453px #fff,
    1218px 897px #fff, 1385px 278px #fff, 1572px 58px #fff, 184px 1507px #fff,
    941px 167px #fff, 330px 1154px #fff, 145px 833px #fff, 674px 1797px #fff,
    495px 9px #fff, 1109px 149px #fff, 1857px 344px #fff, 784px 1655px #fff,
    309px 1097px #fff, 1197px 627px #fff, 1115px 815px #fff, 27px 1222px #fff,
    1138px 1833px #fff, 429px 945px #fff, 317px 869px #fff, 710px 7px #fff,
    241px 1794px #fff, 1457px 912px #fff, 1085px 732px #fff, 612px 274px #fff,
    1726px 1228px #fff, 1758px 1256px #fff, 1696px 1965px #fff,
    470px 1555px #fff, 1989px 500px #fff, 1339px 1159px #fff, 987px 910px #fff,
    1522px 795px #fff, 707px 1954px #fff, 448px 1478px #fff, 1540px 1662px #fff,
    318px 846px #fff, 1911px 1481px #fff, 811px 1666px #fff, 361px 1258px #fff,
    1329px 1300px #fff, 1880px 704px #fff, 536px 596px #fff, 1750px 614px #fff,
    654px 1627px #fff, 381px 70px #fff, 1001px 1922px #fff, 617px 1839px #fff,
    719px 687px #fff, 1104px 1198px #fff, 1797px 477px #fff, 492px 503px #fff,
    6px 1339px #fff, 1670px 237px #fff, 688px 1473px #fff, 583px 511px #fff,
    595px 1696px #fff, 196px 1834px #fff, 1827px 1865px #fff, 412px 114px #fff,
    869px 308px #fff, 1530px 551px #fff, 1570px 1437px #fff, 278px 660px #fff,
    9px 1258px #fff, 1083px 525px #fff, 983px 156px #fff, 1726px 1480px #fff,
    1907px 1399px #fff, 697px 1255px #fff, 1975px 764px #fff, 1838px 587px #fff,
    1595px 469px #fff, 1539px 1947px #fff, 1616px 1469px #fff, 270px 578px #fff,
    1441px 1530px #fff, 1705px 1582px #fff, 699px 1289px #fff,
    1506px 1774px #fff, 707px 1226px #fff, 1747px 1806px #fff, 1677px 927px #fff,
    1115px 609px #fff, 839px 724px #fff, 1923px 1576px #fff, 1282px 582px #fff,
    99px 36px #fff, 1240px 288px #fff, 1682px 468px #fff, 1831px 1614px #fff,
    524px 498px #fff, 1722px 253px #fff, 1048px 78px #fff, 1527px 268px #fff,
    178px 890px #fff, 658px 1567px #fff, 1490px 320px #fff, 312px 1708px #fff,
    302px 223px #fff, 139px 727px #fff, 141px 1418px #fff, 1911px 1776px #fff,
    1372px 339px #fff, 1764px 1196px #fff, 176px 229px #fff, 1387px 1808px #fff,
    397px 1460px #fff, 1108px 826px #fff, 612px 1343px #fff, 1513px 1225px #fff,
    211px 596px #fff, 1306px 1691px #fff, 1900px 1680px #fff, 232px 597px #fff,
    1516px 290px #fff, 1331px 1517px #fff, 1280px 1799px #fff, 429px 140px #fff,
    94px 1183px #fff, 898px 147px #fff, 1766px 249px #fff, 1875px 1114px #fff,
    1041px 290px #fff, 420px 1984px #fff, 271px 1038px #fff, 938px 555px #fff,
    1505px 975px #fff, 1128px 1589px #fff, 755px 1524px #fff, 1933px 1361px #fff,
    1269px 1196px #fff, 1607px 704px #fff, 1955px 1883px #fff, 338px 1005px #fff,
    511px 1839px #fff, 1078px 1819px #fff, 1601px 644px #fff, 1948px 207px #fff,
    973px 316px #fff, 429px 640px #fff, 1661px 1926px #fff, 1327px 1078px #fff,
    1804px 495px #fff, 1837px 392px #fff, 455px 66px #fff, 1343px 1196px #fff,
    1272px 1526px #fff, 1440px 1664px #fff, 31px 1866px #fff, 860px 826px #fff,
    387px 140px #fff, 1906px 655px #fff, 438px 603px #fff, 1326px 672px #fff,
    705px 77px #fff, 436px 1059px #fff, 1218px 1151px #fff, 1679px 1285px #fff,
    1004px 1077px #fff, 204px 656px #fff, 1141px 1403px #fff, 1790px 627px #fff,
    1264px 906px #fff, 1439px 1878px #fff, 1589px 386px #fff, 1327px 584px #fff,
    369px 896px #fff, 1851px 1096px #fff, 1931px 469px #fff, 1568px 878px #fff,
    1085px 1430px #fff, 1970px 884px #fff, 676px 495px #fff, 1429px 644px #fff,
    1267px 146px #fff, 534px 1996px #fff, 1595px 353px #fff, 1579px 863px #fff,
    1896px 1079px #fff, 1470px 1213px #fff, 87px 1632px #fff, 72px 1268px #fff,
    504px 876px #fff;
  animation: animStar 100s linear infinite;
}
#stars2:after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: 71px 795px #fff, 57px 324px #fff, 1321px 88px #fff,
    1964px 677px #fff, 180px 802px #fff, 360px 16px #fff, 1528px 321px #fff,
    1244px 1926px #fff, 1130px 1729px #fff, 1239px 1058px #fff,
    1571px 504px #fff, 741px 1733px #fff, 1980px 98px #fff, 748px 243px #fff,
    1321px 1434px #fff, 1010px 1711px #fff, 1109px 1453px #fff,
    1218px 897px #fff, 1385px 278px #fff, 1572px 58px #fff, 184px 1507px #fff,
    941px 167px #fff, 330px 1154px #fff, 145px 833px #fff, 674px 1797px #fff,
    495px 9px #fff, 1109px 149px #fff, 1857px 344px #fff, 784px 1655px #fff,
    309px 1097px #fff, 1197px 627px #fff, 1115px 815px #fff, 27px 1222px #fff,
    1138px 1833px #fff, 429px 945px #fff, 317px 869px #fff, 710px 7px #fff,
    241px 1794px #fff, 1457px 912px #fff, 1085px 732px #fff, 612px 274px #fff,
    1726px 1228px #fff, 1758px 1256px #fff, 1696px 1965px #fff,
    470px 1555px #fff, 1989px 500px #fff, 1339px 1159px #fff, 987px 910px #fff,
    1522px 795px #fff, 707px 1954px #fff, 448px 1478px #fff, 1540px 1662px #fff,
    318px 846px #fff, 1911px 1481px #fff, 811px 1666px #fff, 361px 1258px #fff,
    1329px 1300px #fff, 1880px 704px #fff, 536px 596px #fff, 1750px 614px #fff,
    654px 1627px #fff, 381px 70px #fff, 1001px 1922px #fff, 617px 1839px #fff,
    719px 687px #fff, 1104px 1198px #fff, 1797px 477px #fff, 492px 503px #fff,
    6px 1339px #fff, 1670px 237px #fff, 688px 1473px #fff, 583px 511px #fff,
    595px 1696px #fff, 196px 1834px #fff, 1827px 1865px #fff, 412px 114px #fff,
    869px 308px #fff, 1530px 551px #fff, 1570px 1437px #fff, 278px 660px #fff,
    9px 1258px #fff, 1083px 525px #fff, 983px 156px #fff, 1726px 1480px #fff,
    1907px 1399px #fff, 697px 1255px #fff, 1975px 764px #fff, 1838px 587px #fff,
    1595px 469px #fff, 1539px 1947px #fff, 1616px 1469px #fff, 270px 578px #fff,
    1441px 1530px #fff, 1705px 1582px #fff, 699px 1289px #fff,
    1506px 1774px #fff, 707px 1226px #fff, 1747px 1806px #fff, 1677px 927px #fff,
    1115px 609px #fff, 839px 724px #fff, 1923px 1576px #fff, 1282px 582px #fff,
    99px 36px #fff, 1240px 288px #fff, 1682px 468px #fff, 1831px 1614px #fff,
    524px 498px #fff, 1722px 253px #fff, 1048px 78px #fff, 1527px 268px #fff,
    178px 890px #fff, 658px 1567px #fff, 1490px 320px #fff, 312px 1708px #fff,
    302px 223px #fff, 139px 727px #fff, 141px 1418px #fff, 1911px 1776px #fff,
    1372px 339px #fff, 1764px 1196px #fff, 176px 229px #fff, 1387px 1808px #fff,
    397px 1460px #fff, 1108px 826px #fff, 612px 1343px #fff, 1513px 1225px #fff,
    211px 596px #fff, 1306px 1691px #fff, 1900px 1680px #fff, 232px 597px #fff,
    1516px 290px #fff, 1331px 1517px #fff, 1280px 1799px #fff, 429px 140px #fff,
    94px 1183px #fff, 898px 147px #fff, 1766px 249px #fff, 1875px 1114px #fff,
    1041px 290px #fff, 420px 1984px #fff, 271px 1038px #fff, 938px 555px #fff,
    1505px 975px #fff, 1128px 1589px #fff, 755px 1524px #fff, 1933px 1361px #fff,
    1269px 1196px #fff, 1607px 704px #fff, 1955px 1883px #fff, 338px 1005px #fff,
    511px 1839px #fff, 1078px 1819px #fff, 1601px 644px #fff, 1948px 207px #fff,
    973px 316px #fff, 429px 640px #fff, 1661px 1926px #fff, 1327px 1078px #fff,
    1804px 495px #fff, 1837px 392px #fff, 455px 66px #fff, 1343px 1196px #fff,
    1272px 1526px #fff, 1440px 1664px #fff, 31px 1866px #fff, 860px 826px #fff,
    387px 140px #fff, 1906px 655px #fff, 438px 603px #fff, 1326px 672px #fff,
    705px 77px #fff, 436px 1059px #fff, 1218px 1151px #fff, 1679px 1285px #fff,
    1004px 1077px #fff, 204px 656px #fff, 1141px 1403px #fff, 1790px 627px #fff,
    1264px 906px #fff, 1439px 1878px #fff, 1589px 386px #fff, 1327px 584px #fff,
    369px 896px #fff, 1851px 1096px #fff, 1931px 469px #fff, 1568px 878px #fff,
    1085px 1430px #fff, 1970px 884px #fff, 676px 495px #fff, 1429px 644px #fff,
    1267px 146px #fff, 534px 1996px #fff, 1595px 353px #fff, 1579px 863px #fff,
    1896px 1079px #fff, 1470px 1213px #fff, 87px 1632px #fff, 72px 1268px #fff,
    504px 876px #fff;
}

#stars3 {
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: 736px 40px #fff, 582px 660px #fff, 407px 520px #fff,
    1641px 695px #fff, 414px 419px #fff, 1777px 171px #fff, 141px 1142px #fff,
    945px 75px #fff, 240px 1270px #fff, 1835px 797px #fff, 557px 1763px #fff,
    985px 1790px #fff, 1478px 475px #fff, 412px 142px #fff, 1988px 674px #fff,
    1377px 1766px #fff, 1137px 1915px #fff, 810px 980px #fff, 609px 1472px #fff,
    1749px 1859px #fff, 1364px 1292px #fff, 847px 431px #fff, 1921px 1977px #fff,
    573px 1899px #fff, 1790px 1646px #fff, 1575px 650px #fff, 520px 747px #fff,
    1808px 1091px #fff, 1754px 923px #fff, 1875px 637px #fff, 6px 1110px #fff,
    1245px 979px #fff, 1070px 1932px #fff, 623px 1109px #fff, 8px 1368px #fff,
    1954px 913px #fff, 1743px 735px #fff, 1886px 863px #fff, 339px 461px #fff,
    1095px 371px #fff, 213px 1240px #fff, 684px 1345px #fff, 1702px 1804px #fff,
    955px 1831px #fff, 282px 14px #fff, 457px 1224px #fff, 1961px 725px #fff,
    1389px 1300px #fff, 794px 530px #fff, 1302px 184px #fff, 365px 759px #fff,
    535px 992px #fff, 1186px 50px #fff, 359px 1202px #fff, 1196px 121px #fff,
    1691px 1514px #fff, 69px 985px #fff, 1167px 1893px #fff, 1977px 1446px #fff,
    1316px 1463px #fff, 167px 849px #fff, 37px 779px #fff, 1845px 49px #fff,
    947px 936px #fff, 657px 598px #fff, 1024px 499px #fff, 161px 662px #fff,
    557px 1016px #fff, 1437px 1588px #fff, 929px 1874px #fff, 883px 397px #fff,
    627px 1340px #fff, 359px 1682px #fff, 1535px 11px #fff, 110px 1358px #fff,
    965px 1803px #fff, 791px 1824px #fff, 1739px 498px #fff, 1717px 137px #fff,
    1715px 568px #fff, 1417px 1045px #fff, 647px 212px #fff, 679px 113px #fff,
    1032px 1662px #fff, 551px 1139px #fff, 1164px 1303px #fff,
    1573px 1299px #fff, 192px 992px #fff, 254px 1701px #fff, 1557px 1314px #fff,
    458px 792px #fff, 1257px 422px #fff, 849px 768px #fff, 365px 1290px #fff,
    363px 1267px #fff, 1821px 1390px #fff, 242px 529px #fff, 53px 1573px #fff,
    944px 1442px #fff, 1555px 1804px #fff;
  animation: animStar 150s linear infinite;
}
#stars3:after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: 736px 40px #fff, 582px 660px #fff, 407px 520px #fff,
    1641px 695px #fff, 414px 419px #fff, 1777px 171px #fff, 141px 1142px #fff,
    945px 75px #fff, 240px 1270px #fff, 1835px 797px #fff, 557px 1763px #fff,
    985px 1790px #fff, 1478px 475px #fff, 412px 142px #fff, 1988px 674px #fff,
    1377px 1766px #fff, 1137px 1915px #fff, 810px 980px #fff, 609px 1472px #fff,
    1749px 1859px #fff, 1364px 1292px #fff, 847px 431px #fff, 1921px 1977px #fff,
    573px 1899px #fff, 1790px 1646px #fff, 1575px 650px #fff, 520px 747px #fff,
    1808px 1091px #fff, 1754px 923px #fff, 1875px 637px #fff, 6px 1110px #fff,
    1245px 979px #fff, 1070px 1932px #fff, 623px 1109px #fff, 8px 1368px #fff,
    1954px 913px #fff, 1743px 735px #fff, 1886px 863px #fff, 339px 461px #fff,
    1095px 371px #fff, 213px 1240px #fff, 684px 1345px #fff, 1702px 1804px #fff,
    955px 1831px #fff, 282px 14px #fff, 457px 1224px #fff, 1961px 725px #fff,
    1389px 1300px #fff, 794px 530px #fff, 1302px 184px #fff, 365px 759px #fff,
    535px 992px #fff, 1186px 50px #fff, 359px 1202px #fff, 1196px 121px #fff,
    1691px 1514px #fff, 69px 985px #fff, 1167px 1893px #fff, 1977px 1446px #fff,
    1316px 1463px #fff, 167px 849px #fff, 37px 779px #fff, 1845px 49px #fff,
    947px 936px #fff, 657px 598px #fff, 1024px 499px #fff, 161px 662px #fff,
    557px 1016px #fff, 1437px 1588px #fff, 929px 1874px #fff, 883px 397px #fff,
    627px 1340px #fff, 359px 1682px #fff, 1535px 11px #fff, 110px 1358px #fff,
    965px 1803px #fff, 791px 1824px #fff, 1739px 498px #fff, 1717px 137px #fff,
    1715px 568px #fff, 1417px 1045px #fff, 647px 212px #fff, 679px 113px #fff,
    1032px 1662px #fff, 551px 1139px #fff, 1164px 1303px #fff,
    1573px 1299px #fff, 192px 992px #fff, 254px 1701px #fff, 1557px 1314px #fff,
    458px 792px #fff, 1257px 422px #fff, 849px 768px #fff, 365px 1290px #fff,
    363px 1267px #fff, 1821px 1390px #fff, 242px 529px #fff, 53px 1573px #fff,
    944px 1442px #fff, 1555px 1804px #fff;
}

#title {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  color: #fff;
  text-align: center;
  font-family: "lato", sans-serif;
  font-weight: 300;
  font-size: 50px;
  letter-spacing: 10px;
  margin-top: -60px;
  padding-left: 10px;
}
#title span {
  background: -webkit-linear-gradient(white, #38495a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@keyframes animStar {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-2000px);
  }
}
