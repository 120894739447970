.image-item {
  padding: 10px;
}
/* .container {
  margin-top: 20px;
} */
.container-with-dots {
  margin-top: 20px;
  padding-bottom: 10px;
}
.react-multi-carousel-dot-list {
  bottom: 20px !important;
}
#__next {
  overflow: scroll;
  margin-bottom: 40px;
}
.react-multi-carousel-dot:focus {
  outline: none !important;
}
.react-multi-carousel-dot button {
  outline: none;
  border-color: grey !important;
  border-width: 0px;
  background: rgb(170, 170, 170);
}

.react-multi-carousel-dot--active button {
  background: #fff;
}

a {
  color: inherit;
  text-decoration: none;
}
.first-carousel-container {
  margin-bottom: 50px;
}

.react-multi-carousel-list {
  background-color: #212121;
  /* background-color: #1c1c1c; */
  /* background-color:#171717; */
}
