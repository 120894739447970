/*==========================================*/
/* YouTube Subscribe and other share button */
/*==========================================*/

/* c means container here */
.c-share {
  box-shadow: inset 1px 1px 10px black; /* [1] */
  padding: 1rem;

  display: flex;
  flex: column;
  align-items: center;
  justify-content: flex-start;

  height: 100%;
}

.youtubeSubscribe {
  flex: 1;
  /* opacity: 0; */
}

/* css from react share demo */
.c-network {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
}

.network {
  vertical-align: top;
  display: inline-block;
  text-align: center;
  margin: 1.5rem 0.5rem 1.5rem;
}

.network__share-button {
  cursor: pointer;
}

.network__share-button:hover:not(:active) {
  opacity: 0.75;
}

.network__share__count {
  margin-top: 0.1rem;
  font-size: 0.5rem;
}
