.headroom {
  // transition: top 0.5s ease-in-out;
  transition: unset!important;
}
.headroom--initial {
  top: -80px;
}
.headroom--pinned {
  position: fixed;
  top: 0;
  width: 100%;
}
.headroom--unpinned {
  top: -80px;
}
