.height100{
  height: 100vh;  
}


.full-screen.register-page .card-register .ae input {
  margin-bottom: 0px;
}

.react-multiple-carousel__arrow:focus {
  outline: 0px;
}

// button:focus {
//     /* outline: 1px dotted; */
//     /* outline: 5px auto -webkit-focus-ring-color; */
// }

// #tsparticles {
//     position: fixed;
//     top:0;
//     width: 100%;
//     height: 100vh;
//     z-index: 0;
// }
.preparticles {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}
#tsparticles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.parallax-bg100 {
  //iosは効かない？
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
}

.backimage0::after {
  content: "";
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: url("../img/envato/back0-min.jpg") center no-repeat;
  background-size: cover;
}

.backimage1::after {
  content: "";
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: url("../img/envato/back1-min.jpg") center no-repeat;
  background-size: cover;

  // overflow: hidden;
  // -webkit-backface-visibility:hidden;
  // backface-visibility:hidden;
  // z-index: 9999;
}

.backimage2::after {
  content: "";
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: url("../img/envato/back2-min.jpg") center no-repeat;
  background-size: cover;
}

.backimage3::after {
  content: "";
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: url("../img/envato/back3-min.jpg") center no-repeat;
  background-size: cover;
}

.loading-gray {
  // transition: "all 800ms ease-in-out";
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  // background: #111;
  background: #fff;
  // background: #3BABD8;
  z-index: 20001;
}

.loading-black {
  // transition: "all 800ms ease-in-out";
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  // background: #000;
  // background: #1001DF;
  background: #3babd8;
  z-index: 21000;
}

.loading-logo {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 20100;
}

@media screen and (min-width: 767px) {
  .circles li {
    animation: animate1 25s linear infinite;
  }
}

@media (max-width: 1023px) and (min-width: 767px) {
}

@keyframes animate1 {
  0% {
  }

  100% {
  }
}

@keyframes animate2 {
  0% {
  }

  100% {
  }
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  transition: all 0.2s ease-in-out;
  opacity: 1;
}
.fade-exit {
  opacity: 1; /* opacityの規定値は1だが、省略不可 */
}
.fade-exit-active {
  transition: all 0.2s ease-in-out;
  opacity: 0;
}

.letter-enter {
  // transform: "translateY(-10px)";
  opacity: 0;
  color: "yellow";
}
.letter-enter-active {
  transition: all 2s ease-in-out;
  // transform: "translateY(0px)";
  opacity: 1;
  color: "yellow";
}
.letter-exit {
  opacity: 1; /* opacityの規定値は1だが、省略不可 */
  color: "yellow";
}
.letter-exit-active {
  transition: all 2s ease-in-out;
  opacity: 0;
  color: "yellow";
}

.moimg1 {
  overflow: hidden;

  &:not(.notcursor) {
    cursor: pointer;
  }
}

.moimg1 img {
  display: block;
  transition: 0.5s;
}

.moimg1 img:hover {
  transform: scale(1.1, 1.1);
}

.moimg1 .divimg {
  display: block;
  transition: 0.5s;
}

// .moimg1 .divimg:hover {
//   transform: scale(1.1, 1.1);
// }

.moimg1:hover {
  .divimg {
    transform: scale(1.1, 1.1);
  }
}

.card-mask:hover .card-body {
  background-color: rgba(0, 0, 0, 1);
  // background-color: #1f1f1f;

  color: #fff;
}
.card-mask:hover .card-title {
  // background-color: rgba(0, 0, 0, 1);
  color: #fff;
}

.card-mask:hover .img {
  transform: scale(1.1, 1.1);
}

// 中心から
.card-mask {
  overflow: hidden;
  position: relative;
}
.card-mask .caption {
  font-size: 130%;
  color: #fff;
}
.card-mask .mask {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

//  拡大
.card-scale .mask {
  -webkit-transform: scale(0); /* 大きさを０にして表示しない */
  transform: scale(0);
  opacity: 0;
}

.card-scale:hover .mask {
  -webkit-transform: scale(1); /* 大きさを１にして表示する */
  transform: scale(1);
  opacity: 1;
}

//  回転
.card-rotate .mask {
  opacity: 0;
}

.card-rotate:hover .mask {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
  opacity: 1;
}

//  横回転
.card-horizon .mask {
  opacity: 0;
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}

.card-horizon:hover .mask {
  opacity: 1;
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
}

//  縦回転
.card-vertical .mask {
  opacity: 0;
  -webkit-transform: rotateX(-180deg);
  transform: rotateX(-180deg);
}

.card-vertical:hover .mask {
  -webkit-transform: rotateX(0deg);
  transform: rotateX(0deg);
  opacity: 1;
}

.outline {
  filter: dropshadow(color=#ffffff, offX= 0, offY=-1)
    dropshadow(color=#ffffff, offX= 1, offY= 0)
    dropshadow(color=#ffffff, offX= 0, offY= 1)
    dropshadow(color=#ffffff, offX=-1, offY= 0);

  -webkit-text-stroke-color: #fff;
  -webkit-text-stroke-width: 1px;

  // text-shadow:
  // black 2px 0px 0px, black -2px 0px 0px,
  // black 0px -2px 0px, black 0px 2px 0px,
  // black 2px 2px 0px, black -2px 2px 0px,
  // black 2px -2px 0px, black -2px -2px 0px,
  // black 1px 2px 0px, black -1px 2px 0px,
  // black 1px -2px 0px, black -1px -2px 0px,
  // black 2px 1px 0px, black -2px 1px 0px,
  // black 2px -1px 0px, black -2px -1px 0px,
  // black 1px 1px 0px, black -1px 1px 0px,
  // black 1px -1px 0px, black -1px -1px 0px;

  // color:white
}

.solid {
  color: #f0f0f0;
  -webkit-text-stroke-color: #fff;
  -webkit-text-stroke-width: 1px;
}

.outline {
  color: transparent;
  -webkit-text-stroke-color: #fff;
  -webkit-text-stroke-width: 1px;
}

.isIE {
  .outline {
    color: #f0f0f0;
    text-shadow: #fff 1px 1px 0px, #fff -1px 1px 0px, #fff 1px -1px 0px,
      #fff -1px -1px 0px;
  }
}

.square-black {
  background-color: #1b1b1b;
  color: white;
}

.square-beige {
  background-color: #9a887b;
  color: white;
}

.square-white {
  background-color: #f4f3f3;
  color: black;
}

.square-transparent {
  background-color: transparent;
  color: #66615b;
}

.row-hover {
  .row:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.grecaptcha-badge {
  display: none;
}

.pull-left {
  float: left;
}
.pull-right {
  float: right;
}

.black-cool {
  // background-color: #171713;
  background-color: rgba($color: #1b1b1b, $alpha: 1);
  // background-color: rgba($color: #1b1b1b, $alpha: 0.8);

  // background-color: #d1d1d0;
}

.gray-cool {
  background-color: #f1f1f0;
}

.backgroundImage-80 {
  background-color: #b2afab;
  background-position: center center;
  background-size: cover;
  overflow: hidden;
  // position: relative;
  // z-index: 1;
}

.underline {
  // display: block;
  // background: #1495b7;
  // width: 58px;
  // height: 3px;
  // margin: 0 auto;
  text-decoration: underline;
  text-decoration-color: "#C3B189";
}

// style={{
//   textDecoration: "underline",
//   textDecorationColor: "#C3B189",
// }}
// color: "#C3B189"

// text-decoration: underline;
// Copy to Clipboard
// text-decoration: underline dotted;
// text-decoration: underline dotted red;
// text-decoration: green wavy underline;
// text-decoration: underline overline #FF3028;

.under {
  background: linear-gradient(transparent 70%, #c3b189 70%);
  // background: linear-gradient(transparent 70%, #ff99ab 70%);
}

.MuiButtonBase-root .MuiIconButton-root .MuiIconButton-colorInherit {
  outline: 0px auto -webkit-focus-ring-color;
}

.network__share-count {
  display: none;
}
.lg-download.lg-icon {
  display: none;
}

.navbar {
  // background: rgba(0, 0, 0, 0.6) !important;
  background: rgba($color: #1b1b1b, $alpha: 0.6) !important;
  // background: #1b1b1b !important;
  box-shadow: none !important;
}

.MuiTablePagination-actions .MuiButtonBase-root:focus {
  outline: 0px;
}

// .navbar {
//    background: #FFFFFF !important;
// }

// .card-big-shadow {
//   .card[data-color="blue"] {
//     background: #bdd3eb;
//   }
//   .card[data-color="green"] {
//     background: #8fb9df;
//   }
//   .card[data-color="yellow"] {
//     background: #6397ce;
//   }
//   .card[data-color="brown"] {
//     background: #4c7ec0;
//   }
//   .card[data-color="purple"] {
//     background: #85a2c6;
//   }
//   .card[data-color="orange"] {
//     background: #a4d8f0;
//   }
// }

.c-network {
  .network button {
    outline: none;
  }
  justify-content: left !important;
  .network circle {
    display: none;
  }
  .network {
    margin-top: 5px;
    margin-left: 0px;
    // margin-left: -5px;
    margin-bottom: 9px;

  }
}

.react-multi-carousel-list {
  height: 20vh !important;
}
.react-multi-carousel-track {
  height: 20vh !important;
  padding-top: 20px !important;
  // padding-top: 30px!important;
}

.card-big-shadow {
  .card[data-color="blue"] {
    background: rgb(195, 177, 137);
  }
  .card[data-color="green"] {
    background: rgb(171, 171, 171);
  }
  .card[data-color="yellow"] {
    background: rgb(195, 177, 137);
  }
  .card[data-color="brown"] {
    background: rgb(171, 171, 171);
  }
  .card[data-color="purple"] {
    background: rgb(195, 177, 137);
  }
  .card[data-color="orange"] {
    background: rgb(171, 171, 171);
  }
}

// .card-big-shadow {
//   // card-just-text h-100
//   .card {
//     .card-title {
//       // background: #F1F1F0;
//       color: #1e1e1a !important;
//     }
//     .card-description {
//       // background: #F1F1F0;
//       color: #1e1e1a !important;
//     }
//     .card-body {
//       // background: #F1F1F0;
//       color: #1e1e1a !important;
//     }
//   }

//   .card[data-color="blue"] {
//     background: #f1f1f0;
//     .card-title {
//       color: #17a2b8 !important;
//       font-weight: 600;
//     }
//     .card-category {
//       color: rgb(195, 177, 137) !important;
//     }
    
//   }
//   .card[data-color="green"] {
//     background: #f1f1f0;
//     .card-title {
//       color: #51cbce !important;
//       font-weight: 600;
//     }
//     .card-category {
//       color: rgb(195, 177, 137) !important;
//     }
//   }
//   .card[data-color="yellow"] {
//     background: #f1f1f0;
//     .card-title {
//       color: #fbc658 !important;
//       font-weight: 600;
//     }
//     .card-category {
//       color: rgb(195, 177, 137) !important;
//     }
//   }
//   .card[data-color="brown"] {
//     background: #f1f1f0;
//     .card-title {
//       color: #51cbce !important;
//       font-weight: 600;
//     }
//     .card-category {
//       color: rgb(195, 177, 137) !important;
//     }
//   }
//   .card[data-color="purple"] {
//     background: #f1f1f0;
//     .card-title {
//       color: #17a2b8 !important;
//       font-weight: 600;
//     }
//     .card-category {
//       color: rgb(195, 177, 137) !important;
//     }
//   }
//   .card[data-color="orange"] {
//     background: #f1f1f0;
//     .card-title {
//       color: #dc3545 !important;
//       font-weight: 600;
//     }
//     .card-category {
//       color: rgb(195, 177, 137) !important;
//     }
//   }
// }

// .card-big-shadow {
//   // card-just-text h-100
//   .card {
//     .card-title {
//       // background: #F1F1F0;
//       color: #1e1e1a !important;
//     }
//     .card-description {
//       // background: #F1F1F0;
//       color: #1e1e1a !important;
//     }
//     .card-body {
//       // background: #F1F1F0;
//       color: #1e1e1a !important;
//     }
//   }

//   .card[data-color="blue"] {
//     background: #f1f1f0;
//     .card-title {
//       color: #E27057 !important;
//       font-weight: 600;
//     }
//     .card-category {
//       color: rgb(195, 177, 137) !important;
//     }
    
//   }
//   .card[data-color="green"] {
//     background: #f1f1f0;
//     .card-title {
//       color: #D781A4 !important;
//       font-weight: 600;
//     }
//     .card-category {
//       color: rgb(195, 177, 137) !important;
//     }
//   }
//   .card[data-color="yellow"] {
//     background: #f1f1f0;
//     .card-title {
//       color:#A78EC2 !important;
//       font-weight: 600;
//     }
//     .card-category {
//       color: rgb(195, 177, 137) !important;
//     }
//   }
//   .card[data-color="brown"] {
//     background: #f1f1f0;
//     .card-title {
//       color:#BED94D !important;
//       font-weight: 600;
//     }
//     .card-category {
//       color: rgb(195, 177, 137) !important;
//     }
//   }
//   .card[data-color="purple"] {
//     background: #f1f1f0;
//     .card-title {
//       color: #6CC19A !important;
//       font-weight: 600;
//     }
//     .card-category {
//       color: rgb(195, 177, 137) !important;
//     }
//   }
//   .card[data-color="orange"] {
//     background: #f1f1f0;
//     .card-title {
//       color: #77AFDE !important;
//       font-weight: 600;
//     }
//     .card-category {
//       color: rgb(195, 177, 137) !important;
//     }
//   }
// }

.navbar .navbar-brand {
  padding: 7px 15px;
}

.cursor-pointer {
    cursor: pointer;
}